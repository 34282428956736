import { A } from "@solidjs/router";
import { Component, For, Show, createSignal } from "solid-js";
import { Table, TableWithNumber } from "~/common-types";
import ButtonBar from "~/components/ButtonBar";
import styles from "./PickTable.module.css";

const PickTable: Component<{
  tablesByArea:
    | {
        [area: string]: Table[];
      }
    | undefined;
  selected?: TableWithNumber;
  action:
    | {
        link: true;
      }
    | {
        link: false;
        onClick: (
          table: TableWithNumber,
          previousTable?: TableWithNumber,
        ) => void;
      };
}> = (props) => {
  const areas = () => {
    const areas: { name: string; order: number }[] = [];
    for (const area in props.tablesByArea) {
      areas.push({ name: area, order: props.tablesByArea[area][0].area_order });
    }
    return areas
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => a.order - b.order)
      .map((a) => a.name);
  };
  const [selectedArea, setSelectedArea] = createSignal(0);
  const tables = () => {
    return Object.values(props.tablesByArea || {}).flat();
  };
  const selectedTables = () => {
    return tables().filter((t) => t.area === areas()[selectedArea()]);
  };

  const selectedTableNames = () => {
    return selectedTables().map((t) => {
      const [name, number] = t.name.split(" ");
      return {
        id: t.id,
        fullName: t.name,
        name,
        number,
        order_ids: t.order_ids,
        total: t.total,
        area: t.area,
        area_order: t.area_order,
      };
    });
  };

  return (
    <>
      <div class={styles.Area}>
        <ButtonBar
          buttons={areas().map((a, i) => ({
            text: a,
            selected: selectedArea() === i,
          }))}
          onClick={setSelectedArea}
        />
      </div>
      <ul class={styles.Tables}>
        <For each={selectedTableNames()}>
          {(table) => (
            <li
              class={`${styles.Table} ${
                table.total > 0 ? styles.withTotal : ""
              } ${props.selected?.id === table.id ? styles.selected : ""}`}
            >
              <Show when={props.action.link}>
                <A
                  href={`../${table.fullName}`}
                  aria-label={[table.name, table.number]
                    .filter(Boolean)
                    .join(" ")}
                >
                  <span>{table.name}</span>
                  <span>{table.number}</span>
                  <Show when={table.total > 0}>
                    <span class={styles.total}>
                      {table.total.toLocaleString("it-IT", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </span>
                  </Show>
                </A>
              </Show>
              <Show when={!props.action.link}>
                <button
                  class={styles.tableButton}
                  onClick={() =>
                    !props.action.link &&
                    props.action.onClick(table, props.selected)
                  }
                  aria-label={[table.name, table.number]
                    .filter(Boolean)
                    .join(" ")}
                >
                  <span>{table.name}</span>
                  <span>{table.number}</span>
                  <Show when={table.total > 0}>
                    <span class={styles.total}>
                      {table.total.toLocaleString("it-IT", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}{" "}
                      €
                    </span>
                  </Show>
                </button>
              </Show>
            </li>
          )}
        </For>
      </ul>
    </>
  );
};

export default PickTable;
